.open__user{
  display: flex;
  padding: 24px 24px;
  
  .open__user_avatar{
    margin-right: 12px;
    background: #222222;
    background-size: 400px 50px;
    background-image:			
        linear-gradient(
            90deg, 
            rgba(#000, 0.5), 
            rgba(#000, 0.5),
            rgba(#000, 0.5)
        );
    background-repeat: no-repeat; 
    background-position: right 0px top 150px; 
    animation: shine 1s ease infinite; 
  }
  .open__user__info {
    display: flex;
    flex-direction: column;
    margin-left: 7px;
    .open__relation {
      background-color: #222222;
      font-weight: 400;
      width: 200px;
      height: 30px;
      border-radius: 10px;
      background-size: 400px 20px;
      background-image:			
          linear-gradient(
              90deg, 
              rgba(#000, 0.5), 
              rgba(#000, 0.5),
              rgba(#000, 0.5)
          );
      background-repeat: no-repeat; 
      background-position: right 0px top 150px; 
      animation: shine 1s ease infinite; 
    }
    
    .open__username {
      background: #222222;
      font-weight: 600;
      width: 350px;
      height: 30px;
      border-radius: 10px;
      margin-bottom: 30px;
      background-size: 500px 20px;
      background-image:			
          linear-gradient(
              90deg, 
              rgba(#000, 0.5), 
              rgba(#000, 0.5),
              rgba(#000, 0.5)
          );
      background-repeat: no-repeat; 
      background-position: right 0px top 150px; 
      animation: shine 1s ease infinite; 
    }
  }
  .user__info > span {
    font-size: 14px;
  }
}

@keyframes shine {
to {
    background-position: top 0px left -140px;
}
}