.settings{
    padding-top: 24px;
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: black;
    height: 100%;
    width: 315px;
    border-right: #212121 solid 1px;
    .settings__title{
        margin: 0px 48px 24px 48px;
        padding: 0px 16px;
    }
    .settings__title > span{
        color: white;
        font-size: 25px;
        font-weight: 750;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
    }

    .settings__content{
        height: 100%;
        overflow: auto;
        .settings__content__element{
            padding: 16px 14px;
            color: white;
            font-weight: 450;
            font-size: 14px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            border-radius: 10px;
            cursor: pointer;
            margin: 0px 48px 5px 36px;
        }
        .settings__content__element:hover{
            background: #262626;
        }
        
    }
    
}