

.modal-button{
    font-weight: 600;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 5px 10px 
}

.add-employee-modal .title-modal {
    font-weight: bold;
    color: black;
}

.modal-content-item{
    text-align: center;
    margin: 0 30px;
}
.modal-content-item:last-child{
    margin-bottom: 20px;
}


.post-info{
    display: flex;
    margin-top:10px;
}
.comment{
    overflow: auto;
    max-height: 120px;
}
.post-comment{
    display: flex;
    margin-top:10px;
}

.image {
    flex: 0.8;
    max-width: 567px;
    max-height: 567px;
    .img-slider {
      object-fit: none;
      display: block;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .img-slider-btn {
      margin-right: 10px;
      all: unset;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      background-color: #464646;
      color: white;
      border-radius: 50%;
      padding: 8px;
      cursor: pointer;
      transition: background-color 100ms ease-in-out;
    }
    .img-slider-btn:hover {
      background-color: #606060;
      transform: scale(1.05);
    }
    .delete_image:hover {
      background-color: #606060 !important;
      transform: scale(1.05);
    }
  }

  .modal-content-report{
    display: flex;
    padding: 15px;
    justify-content: space-between;
    border-bottom: rgb(103, 103, 103) solid 1px;
    .report{
        font-weight: 600;
        font-size: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .count{
        font-weight: 600;
        font-size: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}

.modal-content-report:last-child{
    border-radius: 0px 0px 10px 10px;
    border: none;
}
