html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black !important;
  }
  .group {
    display: flex;
    background-color: black;
    height: 100%;
    
    .group__navWraper {
      position: relative;
      width: 240px;
    }
    .group__right{
      height: 100%;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .group__content {
        height: 100%;
        width: 100%;
        .group__header{
          display: flex;
          border-bottom: #212121 solid 1px;
          padding: 24px 16px;
          justify-content: space-between;
          .group__input {
            width: calc(100% - 220px);
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .group__input input {
            background-color: rgb(38, 38, 38);
            width: 333px;
            height: 34px;
            padding: 3px 16px 3px 16px;
            border-radius: 10px;
            border: 0;
            color: white;
            font-size: 15px;
            font-weight: 400;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
              Helvetica, Arial, sans-serif;
          }
          .group__input input:focus {
            outline: none;
          }

          .create__button {
            width: auto;
            align-items: center;
            color: white;
            padding: 7px 15px 8px 15px;
            border: 0;
            font-size: 14px;
            background-color: #363636;
            border-radius: 7px;
            font-weight: 600;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
          }
          .create__button:hover {
              cursor: pointer;
              background-color: rgba(255, 255, 255, 0.128);
          }
        }
  
        .groups{
            display: flex;
            justify-content: center;
            align-items: center;

            .group__items_header{
              color: white;
              padding: 20px 0 20px 4px;
              font-size: 17px;
              font-weight: 550;
              font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                Helvetica, Arial, sans-serif;
            }
            .group__items{
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row; /* Chiều ngang */
              // margin: 30px 0px 50px 0px;
            }
        }
    }
    
}

//Modal
.active-group-modal {
    overflow-y: hidden;
}
.group-modal .btn-group-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.group-modal,
.group-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 3;
}

.group-overlay {
    background: rgba(4, 3, 3, 0.8);

    .sidenav__icon {
        transform: scale(1.05);
    }
}

//more
.more-content {
  border: #353535 solid 0.5px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 400px;
  height: fit-content;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  .more-content-element {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .more-content-element:last-child {
    border-bottom: none;
  }
  
  .more-content-header {
    display:flex;
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    .more-content-title{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 15px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    }
  }

  .group-modal-content {
    height: 360px;
    overflow: auto;
  }

  .more-content-element:hover{
    background: #434343;
  }
  .more-content-element:last-child {
    border-bottom: none;
    border-radius: 0px 0px 10px 10px;
  }
  .more-content-element:first-child {
    border-radius: 10px 10px 0px 0px;
  }

  .more-content-info{
    padding: 15px 0;
    .group__content__info {
      display: flex;
      margin-bottom: 10px;
      margin-top: 10px;
      .group__content__info__subject {
        width: 15%;
        display: flex;
        justify-content: end;
        margin-right: 20px;
      }
      .group__content__info__subject > span {
        margin-top: 5px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
      }
      .group__content__info__user {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-left: 10px;
        .group__changeAvatar {
          color: #0095f6;
          font-size: 14px;
          font-weight: 600;
          font-family: system-ui, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;

          &:hover {
            color: white;
            cursor: pointer;
          }
        }

        .group__username {
          left: 0;
          color: white;
          font-size: 16px;
          font-weight: 550;
          font-family: system-ui, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;
        }
      }

      

      .group__content__info__textarea {
        width: 70%;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: #4e4d4d solid 1px;
        border-radius: 5px;
      }
      .group__content__info__textarea > textarea {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #262626;
        border: none;
        resize: none;
        height: 100px;
        overflow: auto;
        font-size: 16px;
        color: white;
        font-weight: 450;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
      }
      .group__content__info__textarea > textarea:focus {
        outline: none;
      }

      .group__content__info__textarea > input {
        padding: 10px;
        border-radius: 10px;
        width: 100%;
        background-color: #262626;
        border: none;
        resize: none;
        font-size: 16px;
        color: white;
        font-weight: 450;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
      }
      .group__content__info__textarea > input:focus {
        outline: none;
      }
      .group__content__info__button > button {
        height: 32px;
        width: 80px;
        margin-left: 10px;
        background-color: #0095f6;
        font-size: 14px;
        color: white;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
          Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
          sans-serif;
        border-radius: 10px;
        border: none;
        cursor: pointer;
      }
    }
  }

}

@media (max-width: 1064px){
    .group {
      .group__navWraper {
        position: relative;
        width: 80px;
      }
    }
  }
  
    