.chats__user{
    display: flex;
    padding: 8px 24px;
    
    .chats__user_avatar{
        position: relative;
        display: inline-block;
        width: 44px;
        height: 44px;
        margin-right: 12px;
    }
    .chats__user_avatar > img {
        border-radius: 50%;
        object-fit: cover;
    }
    .chats__user_avatar > span {
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: green;
        bottom: 5px;
        border: black solid 3px;
        left: 85%;
        transform: translate(-50%, 50%);
    }
    .chats__user__info {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 7px;
        justify-content: center;
        .chats__relation {
            color: #A8A8A8;
            font-weight: 400;
            font-size: 12px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }
        
        .chats__username {
            color: white;
            font-weight: 600;
            font-size: 14px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            margin-bottom: 5px;
        }
    }
    .unread__info {
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        justify-content: center;
        right: 0;
        .unread{
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #0095f6;
        }
    }
}
.chats__user:hover {
cursor: pointer;
background-color: rgba(255, 255, 255, 0.128);
}


@media (max-width: 900px){
    .chats__user {
        width: 72px; 
        .chats__user_avatar{
            margin-right: 10px;
        }
        .chats__user__info{
            display: none;
        }
        .unread__info{
            margin: 0px;
        }
    }
}