.add-btn {
    display: flex;
    align-items: end;
    justify-content: end;
    margin-bottom: 12px;
}
.add-employee-modal{
    .modal-content{
        background-color: white;
    }
    
}
.add-employee-modal input {
    border: 1px solid #eaecf0;
    padding: 0;
    width: 80%;
    margin-left: 10px;
    padding: 10px;
    border-radius: 10px;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.heading-modal {
    padding: 4px;
    margin-top: 10px;
    margin-left: 10px;
}
.modal-content-item{
    margin-left: 30px;
}
.modal-content-item:last-child{
    margin-bottom: 20px;
}

.modal-button{
    font-weight: 600;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 5px 10px 
}

.add-employee-modal .title-modal {
    font-weight: bold;
    color: black;
}

.add-employee-modal .gender label {
    padding-right: 8px;
}
.modal-content-report{
    display: flex;
    padding: 15px;
    justify-content: space-between;
    border-bottom: rgb(103, 103, 103) solid 1px;
    .report{
        font-weight: 600;
        font-size: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    .count{
        font-weight: 600;
        font-size: 16px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
}

.modal-content-report:last-child{
    border-radius: 0px 0px 10px 10px;
    border: none;
}

// .modal-content-report:hover{
//     background-color: rgb(103, 103, 103);
// }

