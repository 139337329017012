html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
}
.chatpage {
    display: flex;
    flex-direction: row;
    background-color: black;
    height: 100%;


    .chatpage__navWraper {
        position: relative;
        .sidenav{
            width: 80px;
            .sidenav__title{
                height: 120px; 
                display: flex; 
                align-items: center;
                .sidenav__title__button{
                  display: block;
                }
                .sidenav__logo{
                  display: none;
                }
            }
            .sidenav__button{
                width: 71%;
                margin: 5px 10px 5px 10px;
            }
            .unread{
                left: 20px;
            }
            .sidenav__button .span {
                display: none;
            }
            
            .sidenav__more{
                width: 80px;
            }
            .sidenav__buttonText{
                display: none;
            }
        }
    }

    .chatpage__sideBar {
        z-index: 0;
        position: relative;
        width: 400px; 
    }
    .chatpage__messages{
        z-index: 0;
        flex: 1;
        height: 100vh;
        .chatInfo {
            height: 75px;
            background-color: #000000;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 16px;
            color: white;
            border-bottom: #212121 solid 1px;
          }
          .chatInfo__user{
            display: flex;
            
            .chatInfo__user_avatar{
                padding: 6px;
                position: relative;
                display: inline-block;
            }
            .chatInfo__user_avatar> img {
                border-radius: 50%;
                object-fit: cover;
            }
            .chatInfo__user_avatar > span {
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: green;
                bottom: 15px;
                border: black solid 3px;
                left: 80%;
                transform: translate(-50%, 50%);
            }
            .chatInfo__user__info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-left: 7px;
                .chatInfo__relation {
                    color: #A8A8A8;
                    font-size: 12px;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 400;
                }
                
                .chatInfo__username {
                    left: 0;
                    color: white;
                    font-size: 16px;
                    font-weight: 550;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                }
            }
        }
    
        .chatIcons {
            display: flex;
            gap: 10px;

            img {
                height: 24px;
                cursor: pointer;
            }
        }

        .chatpage__messages__main{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 93.5%;
            border-radius: 0px 0px 10px 10px;
            .chatpage__messages__image{
                display: flex;
                justify-content: center;
                align-items: center;
                .chatpage__messages__logo{
                  width: auto;
                  height: 100px;
                  color: white;
                }
            }
            
            .chatpage__messages__text{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            
            }
            .chatpage__messages__text > span{
            color: white;
            font-size: 20px;
            font-weight: 5500;
            font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            }
        }
    }
}

// THE LOADING EFFECT
.skeleton {
    border-radius: 10px;
    background-color: #222222;
    color: #222222 !important;
    background-image:			
            linear-gradient(
                90deg, 
                rgba(#000, 0), 
                rgba(#000, 0.5),
                rgba(#000, 0)
            );
    background-size: 40px 100%; 
    background-repeat: no-repeat; 
    background-position: left -40px top 0; 
    animation: shine 1s ease infinite; 
}

@keyframes shine {
    to {
        background-position: right -40px top 0;
    }
}


//Modal
.active-message-modal {
    overflow-y: hidden;
  }
  .message-modal .btn-message-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
  }
  
  .message-modal,
  .message-overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 3;
  }
  
  .message-overlay {
    background: rgba(4, 3, 3, 0.8);
  
    .sidenav__icon {
      transform: scale(1.05);
    }
  }
//more
.more-content {
    border: #353535 solid 0.5px;
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    width: 400px;
    height: fit-content;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #262626;
    border-radius: 10px;
    
    .more-content-header {
      display:flex;
      text-align: center;
      padding: 14px 10px;
      border-bottom: #4e4d4d solid 1px;
      color: white;
      .more-content-title{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 15px;
        margin-right: 27px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      }
    }
    .more-content-report {
      text-align: center;
      padding: 14px 10px;
      border-bottom: #4e4d4d solid 1px;
      color: white;
      font-weight: 400;
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      cursor: pointer;
    }
    .more-content-report:hover{
      background: #434343;
    }
    .more-content-report:last-child {
      border-bottom: none;
      border-radius: 0px 0px 10px 10px;
    }
  }

@media (max-width: 900px){
    .chatpage {
        .chatpage__sideBar {
            width: 130px; 
        }
    }
}