.wrapper {
  background: #efefef;
  padding: 16px 16px 0px 272px;
  min-height: 88vh;
  @media (max-width: 900px) {
    padding: 16px 16px;
  }
}
.fade{
  background: rgba(4, 3, 3, 0.8);
}
.modal-content {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  border-radius: 10px;
  max-height: 80%;
  max-width: 500px;
  .modal-header {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: #303030 solid 1px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
  .modal-body {
    height: 85%;
    overflow: auto;
  }
  .modal-footer {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}
