html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black !important;
}
.group {
    display: flex;
    background-color: black;
    height: 100%;

    .group__navWraper {
        position: relative;
        width: 240px;
    }
    .group__right {
        height: 100%;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .group__content {
        height: 774px;
        width: 100%;
        .group__bio {
            padding: 0px 30px 30px 30px;
        }
        .group__bio span {
            font-size: 16px;
            font-weight: 700;
            font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                sans-serif;
            color: #606060;
        }
        .group__header {
            border-bottom: #202020 solid 1px;

            .group_avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #0f0f0f;
                border-radius: 0px 0px 20px 20px;
            }
            .group_avatar img {
                max-width: 100%;
                max-height: 400px;
            }
            .group__info div {
                color: white;
            }

            .group__info {
                padding: 30px;
                display: flex;
                justify-content: space-between;
                .group__info__2 {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: auto;
                    .post__more {
                        margin-left: 10px;
                        color: white;
                        cursor: pointer;
                    }
                    .post__more:hover {
                        color: rgb(110, 110, 110);
                        cursor: pointer;
                    }
                }
                .group__info__2 button {
                    align-items: center;
                    color: white;
                    padding: 7px 15px 8px 10px;
                    border: 0;
                    font-size: 14px;
                    margin: 5px 0px 5px 10px;
                    background-color: #363636;
                    border-radius: 7px;
                    font-weight: 600;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }
                .group__info__2 button:hover {
                    cursor: pointer;
                    background-color: rgba(255, 255, 255, 0.128);
                }

                .group__name {
                    margin-bottom: 10px;
                }

                .group__name > span {
                    font-size: 28px;
                    font-weight: 700;
                    align-items: center;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }

                .group__follow > span {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0px 0px 0px 0px;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }
                .group__follow > a {
                    font-size: 16px;
                    font-weight: 400;
                    margin: 0px 0px 0px 40px;
                    align-items: center;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }
            }
        }
        

        .postpage__content {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container-post {
            min-height: 500px;
            max-height: 650px;
            margin-bottom: 20px;
            margin-top: 20px;
            border: #606060 solid 1px;
            // border-radius: 10px 10px 10px 10px;
            display: flex;
            background-color: black;
            width: 85%;
            .image-post {
                display: flex;
                overflow: hidden;
                align-items: center;
                flex: 0.8;
                max-width: 900px;
                max-height: 750px;
                .img-post-slider {
                    object-fit: none;
                    display: block;
                    flex-shrink: 0;
                    flex-grow: 0;
                }
                .img-post-slider-btn {
                    margin-right: 10px;
                    all: unset;
                    width: 16px;
                    height: 16px;
                    display: block;
                    position: absolute;
                    background-color: #464646;
                    color: white;
                    border-radius: 50%;
                    padding: 8px;
                    cursor: pointer;
                    transition: background-color 100ms ease-in-out;
                }
                .img-post-slider-btn:hover {
                    background-color: #606060;
                    transform: scale(1.05);
                }
        }
        .post-caption {
            flex: 0.2;
            margin-left: 1px;
            border-left: #353535 solid 0.5px;
            max-width: 480px;
            min-width: 380px;
            background-color: #000;
            border-radius: 0px 20px 20px 0px;
            .postInfo-user {
                height: 10%;
                display: flex;
                border-bottom: #353535 solid 0.5px;

            .postInfo-user-avatar {
                display: flex;
                align-items: center;
                // padding: 10px 0px 10px 16px;
                position: relative;
                // display: inline-block;
            }
            .postInfo-user-avatar > img {
                border-radius: 50%;
                object-fit: cover;
            }

            .postInfo-user-info {
                padding: 10px 16px 10px 5px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-left: 7px;

                .postInfo-username {
                    left: 0;
                    color: white;
                    font-size: 14px;
                    font-weight: 550;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }
            }
            }
            .post-text {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .post-text > textarea {
                width: 90%;
                background-color: #262626;
                border: none;
                resize: none;
                height: 180px;
                overflow: auto;
                font-size: 16px;
                color: white;
                font-weight: 450;
                font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                    sans-serif;
            }
            .post-text > textarea:focus {
                outline: none;
            }
        }
        }
        .post-comment {
            overflow-y: auto;
            overflow-x: hidden;
            width: 100%;
            height: 60%;
            border-bottom: #353535 solid 0.5px;

            .post-comment-user {
                width: 85%;
                display: flex;
                padding: 8px 24px;

                .post-comment-user-avatar {
                    position: relative;
                    display: inline-block;
                    width: 44px;
                    height: 44px;
                }
                .post-comment-user-avatar > img {
                    border-radius: 50%;
                    margin-top: 3px;
                    object-fit: cover;
                }

                .post-comment-user-info {
                    width: 100%;
                    margin-bottom: 7px;
                    display: inline-block;
                    word-wrap: break-word;
                    .post-comment-content {
                        width: 100%;
                        max-width: max-content;
                        white-space: pre-line;
                        word-wrap: break-word;
                        color: white;
                        font-weight: 400;
                        font-size: 14px;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                        Helvetica, Arial, sans-serif;
                    }

                    .post-comment-username {
                        margin-right: 5px;
                        color: white;
                        font-weight: 700;
                        font-size: 14px;
                        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                        Helvetica, Arial, sans-serif;
                        margin-bottom: 5px;
                    }
                }
            }

            .moreCmt{
                display: none;
                cursor: pointer;
            }
            
            .post-comment-user:hover .moreCmt{
                display: flex;
                justify-content: center; 
                align-items: center;
                margin-right: 10px;
            }
        }

        //Modal
        .active-post-modal {
            overflow-y: hidden;
        }
        .post-modal .btn-post-modal {
            padding: 10px 20px;
            display: block;
            margin: 100px auto 0;
            font-size: 18px;
        }

        .post-modal,
        .post-overlay {
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: fixed;
            z-index: 3;
        }

        .post-overlay {
            background: rgba(4, 3, 3, 0.8);

        .sidenav__icon {
            transform: scale(1.05);
        }
        }
        //more
        .more-content {
            border: #353535 solid 0.5px;
            position: absolute;
            z-index: 3;
            top: 50%;
            left: 50%;
            width: 400px;
            height: fit-content;
            transform: translate(-50%, -50%);
            line-height: 1.4;
            background: #262626;
            border-radius: 10px;
            .more-content-element {
                text-align: center;
                padding: 14px 10px;
                border-bottom: #4e4d4d solid 1px;
                color: white;
                font-weight: 500;
                font-size: 15px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
                Helvetica, Arial, sans-serif;
                cursor: pointer;
            }
            .more-content-element:last-child {
                border-bottom: none;
            }
            
            .more-content-header {
                display:flex;
                text-align: center;
                padding: 14px 10px;
                border-bottom: #4e4d4d solid 1px;
                color: white;
                .more-content-title{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 15px;
                margin-right: 27px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                }
            }
            .more-content-element:hover{
                background: #434343;
            }
            .more-content-element:last-child {
                border-bottom: none;
                border-radius: 0px 0px 10px 10px;
            }
            .more-content-element:first-child {
                border-radius: 10px 10px 0px 0px;
            }

            .more-content-report {
                text-align: center;
                padding: 14px 10px;
                border-bottom: #4e4d4d solid 1px;
                color: white;
                font-weight: 400;
                font-size: 14px;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
                cursor: pointer;
            }
            .more-content-report:last-child {
                border-bottom: none;
                border-radius: 0px 0px 10px 10px;
            }
            .more-content-report:hover{
                background: #434343;
            }
            .more-content-element:hover{
                background: #434343;
            }
        }
    }
}

@media (max-width: 1064px) {
    .group {
      .group__navWraper {
        position: relative;
        width: 80px;
      }
    }
  }