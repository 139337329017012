.main {
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-top: 50px;
  max-width: 350px;
}

.center-screen {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(219, 219, 219);
  border-radius: 1px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font: inherit;
  font-size: 100%;
  margin: 0 0 10px;
  padding: 10px 0;
  position: relative;
  vertical-align: baseline;
  margin-bottom: 10px;
}

.cursor {
  cursor: pointer;
}

.logo {
  &--container {
    margin-top: 36px;
    overflow-y: visible;
    margin-bottom: 12px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: stretch;
    overflow-x: visible;
    align-self: auto;
    justify-content: flex-start;
    position: relative;
    flex-grow: 0;
  }

  &--main {
    background-image: url(../../asset/images/logo-black.png);
    background-position: 0px 0px;
    background-size: 210px 55px;
    width: 210px;
    height: 70px;
    background-repeat: no-repeat;
    display: inline-block;
  }
}

.form {
  &--container {
    margin-bottom: 10px;
    max-width: 350px;
    width: 100%;
  }

  &--main {
    border: 0;
    display: flex;
    flex-direction: column;
    font: inherit;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }

  &--separate {
    overflow-x: hidden;
    overflow-y: hidden;

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-color: transparent;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    position: relative;
    flex-grow: 0;
  }
}

.otp {
  &__container {
    border-left-width: 1px;
    font-size: 20px !important;
    background-color: rgb(250, 250, 250);
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

    input {
      width: 100% !important;
      height: 38px !important;
      color: black;

      border-radius: 4px;
      border: 1px solid black;
    }
  }
}

.input {
  &--margin {
    margin: 0 40px 6px;
  }

  &--container {
    border-left-width: 1px;
    font-size: 14px;
    border-right-style: solid;
    background-color: rgb(250, 250, 250);
    border-right-width: 1px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    color: rgb(0, 0, 0);

    flex-direction: row;
    border-top-left-radius: 3px;
    border-right-color: rgb(219, 219, 219);
    position: relative;
    border-left-color: rgb(219, 219, 219);
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    border-top-color: rgb(219, 219, 219);
    border-top-width: 1px;
    border-top-style: solid;
  }

  &--label {
    border: 0;
    display: flex;
    flex: 1 0 0px;
    font: inherit;
    font-size: 100%;
    height: 36px;
    margin: 0;
    min-width: 0;
    padding: 0;
    position: relative;
    vertical-align: baseline;
  }

  &--span {
    border: 0;
    color: rgb(var(--ig-secondary-text));
    font: inherit;
    font-size: var(--system-12-font-size);
    height: 36px;
    left: 8px;
    line-height: 36px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-overflow: ellipsis;
    transform-origin: left;
    transition: transform ease-out 0.1s;
    user-select: none;
    vertical-align: baseline;
    white-space: nowrap;
  }

  &--user {
    font-size: 16px;
    appearance: none;
    text-align: left;
    background: rgb(250, 250, 250);
    border: 0;
    flex: 1 0 auto;
    margin: 0;
    outline: none;
    overflow: hidden;
    padding: 9px 0 7px 8px;
    text-overflow: ellipsis;
  }
}

.button {
  &--text {
    overflow-y: visible;
    align-content: stretch;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: transparent;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    align-items: stretch;
    overflow-x: visible;
    align-self: auto;
    justify-content: flex-start;
    position: relative;
    flex-grow: 0;
    color: rgb(255, 255, 255);
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-transform: inherit;
    user-select: none;
  }

  &--login {
    appearance: none;
    background: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    font-family: var(--font-family-system);
    font-size: 14px;
    font-weight: var(--font-weight-system-semibold);
    padding: 7px 16px !important;
    pointer-events: auto;
    text-align: center;
    text-overflow: ellipsis;
    text-transform: inherit;
    user-select: none;
    width: auto;
    text-align: center;
    border: none;
    border-radius: 8px;
    color: rgb(var(--web-always-white));
    position: relative;
    background-color: rgb(0, 149, 246);

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  &--container {
    margin-left: 40px;
    overflow-y: visible;
    align-content: stretch;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    background-color: transparent;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    margin-top: 8px;
    align-items: stretch;
    overflow-x: visible;
    align-self: auto;
    justify-self: flex-start;
    position: relative;
    margin-right: 40px;
    margin-bottom: 8px;
    flex-grow: 0;
  }
}

.forgot {
  &--link {
    text-decoration: none;
    color: rgb(0, 55, 107);
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px !important;
    text-align: center !important;
    display: inline;
    padding-left: 0;
    background-color: transparent;
    touch-action: manipulation;
    padding-top: 0;
    margin-top: 0;
    border-left: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: 0;
    cursor: pointer;
    margin-left: 0;
    -webkit-tap-highlight-color: transparent;
    border-right: 0;
    padding-bottom: 0;
    text-align: inherit;
    margin-right: 0;
    list-style: none;
    outline: none;
    border-bottom: 0;
    border-top: 0;
  }

  &--text {
    line-height: 16px;
    --base-line-clamp-line-height: 16px;
    overflow-y: hidden;
    font-weight: 400;
    min-width: 0;
    margin-bottom: 0 !important;
    word-wrap: break-word;
    overflow-x: hidden;
    position: relative;
    display: block;
    white-space: pre-line;
    font-size: 15px;
    max-width: 100%;
    margin-left: 0 !important;
    word-break: break-word;
    margin-top: 0 !important;
    text-align: center;
  }
}

.signup {
  &--container {
    line-height: 18px;

    font-size: 14px;
    overflow-y: visible;
    font-weight: 400;
    min-width: 0;
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    word-wrap: break-word;
    color: rgb(115, 115, 115);
    overflow-x: visible;
    position: relative;
    display: block;
    white-space: pre-line;
    max-width: 100%;
    margin-left: 0 !important;
    word-break: break-word;
    margin-top: 0 !important;
  }

  &--question {
    color: rgb(0, 0, 0);
    font-size: 14px;
    text-align: center;
  }

  &--link {
    color: rgb(0, 149, 246);
    text-decoration: none;
    display: inline;
    padding-left: 0;
    background-color: transparent;
    touch-action: manipulation;
    padding-top: 0;
    margin-top: 0;
    border-left: 0;
    margin-bottom: 0;
    box-sizing: border-box;
    padding-right: 0;
    cursor: pointer;
    margin-left: 0;
    -webkit-tap-highlight-color: transparent;
    border-right: 0;
    padding-bottom: 0;
    text-align: inherit;
    margin-right: 0;
    list-style: none;
    outline: none;
    border-bottom: 0;
    border-top: 0;
    font-size: 14px;
    font-weight: 400;
    white-space: pre-line;
    word-break: break-word;
  }

  &--text {
    display: inline !important;
    margin: 0 !important;
    color: rgb(0, 149, 246);
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    cursor: pointer;
    text-align: inherit;
    list-style: none;
    white-space: pre-line;
    word-break: break-word;
  }
}

.lock-icon {
  background-repeat: no-repeat;
  background-position: -130px 0;
  height: 96px;
  width: 96px;
  background-image: url(https://static.cdninstagram.com/rsrc.php/v3/y5/r/TJztmXpWTmS.png);
}

.or {
  &--text {
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    flex-shrink: 0;
    font-weight: 600;
    color: rgb(115, 115, 115);
    align-items: stretch;
    font-size: 0.8125rem;
    position: relative;
    line-height: 1.1538;
    flex-grow: 0;
    vertical-align: baseline;
  }
}
