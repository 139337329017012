html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
}
.homepage {
  display: flex;
  flex-direction: row;
  background-color: black;
  height: 100%;
}

.homepage__navWraper {
  position: relative;
  width:240px;
}

.homepage__timeline {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1064px){
  .homepage {
    .homepage__navWraper {
      position: relative;
      width: 80px;
    }
  }
}