.comment__button {
  color: #0095f6;
  background: none;
  border: none;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    color: white;
    cursor: pointer;
  }
}
