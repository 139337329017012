.open__user {
    padding: 8px 24px;
    .open__user_avatar{
      width: 44px;
      height: 44px;
      margin-right: 12px;
    }
    .open__user_avatar > img {
        border-radius: 50%;
        object-fit: cover;
    }
    .open__user__info {
      margin-left: 7px;
      .open__relation {
        color: #a8a8a8;
        font-weight: 400;
      }

      .open__username {
        color: white;
        font-weight: 600;
      }
    }
    .user__info > span {
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
    }
  }
  .open__user:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.128);
    .sidenav__icon {
      transform: scale(1.05);
    }
  }

  .profile-modal__button{
    width: auto;
    align-items: center;
    color: white; 
    padding: 7px 15px 8px 15px;
    border: 0;
    font-size: 14px;
    margin: 5px 0px 5px 10px;
    background-color: #363636;
    border-radius: 7px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.profile-modal__button:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.128);
}
.profile-modal__button__accept{
    width: auto;
    align-items: center;
    color: white; 
    padding: 7px 15px 8px 15px;
    border: 0;
    font-size: 14px;
    margin: 5px 0px 5px 10px;
    background-color:rgb(2, 103, 171);
    border-radius: 7px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.profile-modal__button__accept:hover {
    cursor: pointer;
    background-color:rgb(0, 149, 246);
}