.post {
  width: 550px;
  margin: 20px 0px 20px 0px;
  padding-bottom: 16px;
  border-bottom: #202020 solid 1px;
}

.post__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  .post__more {
    color: white;
    cursor: pointer;
  }
  .post__more:hover {
    color: rgb(110, 110, 110);
    cursor: pointer;
  }
}

.post__headerAuthor {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bolder;
  color: white;
}

.post__headerAuthor > span {
  color: grey;
  font-size: 15px;
  margin-left: 5px;
  font-weight: 500;
}

.post__image {
  width: 100%;
  border-radius: 6px;
  background-color: black;
  .image-post {
    display: flex;
    overflow: hidden;
    align-items: center;
    flex: 0.8;
    max-width: 900px;
    max-height: 750px;
    .img-post-slider {
      object-fit: none;
      display: block;
      max-height: 550px;
      flex-shrink: 0;
      flex-grow: 0;
    }
    .img-post-slider-btn {
      margin-right: 10px;
      all: unset;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      background-color: #464646;
      color: white;
      border-radius: 50%;
      padding: 8px;
      cursor: pointer;
      transition: background-color 100ms ease-in-out;
    }
    .img-post-slider-btn:hover {
      background-color: #606060;
      transform: scale(1.05);
    }
  }
}

.post__footer {
  bottom: 0;
  color: white;
  font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}
.post__footer > span {
  padding: 0px 10px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
}

.post__footerIcons {
  padding: 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.post__iconsMain {
  display: flex;
  align-items: center;
}
.postIcon {
  padding: 7px;
  font-size: 30px;
  color: white;
}
.postIcon:first-child {
  padding: 7px 7px 7px 0px;
}

.postIcon :hover {
  cursor: pointer;
}

.input {
  background-color: black;
  display: flex;
  align-items: center;

  input {
    flex: 1;
    width: 100px;
    border: none;
    outline: none;
    background-color: black;
    color: white;
    font-size: 14px;
    resize: vertical;
    overflow: auto; /* Hiển thị thanh cuộn khi nội dung vượt quá kích thước */
    white-space: pre-wrap; /* Xử lý các dòng mới và khoảng trắng */
    &::placeholder {
      color: #a8a8a8;
    }
  }

  .send {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      height: 24px;
      cursor: pointer;
    }

    button {
      border: none;
      padding: 10px 15px;
      color: white;
      background-color: #8da4f1;
      cursor: pointer;
    }
  }
}

//Modal
.active-post-modal {
  overflow-y: hidden;
}
.post-modal .btn-post-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.post-modal,
.post-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.post-overlay {
  background: rgba(4, 3, 3, 0.8);

  .sidenav__icon {
    transform: scale(1.05);
  }
}
.modal-post-content {
  border: #353535 solid 0.5px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 70%;
  height: 90%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  max-width: 1200px;
  min-width: 300px;
  .modal-post-main {
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    background-color: black;
    .container-post {
      border-radius: 10px 10px 10px 10px;
      display: flex;
      background-color: black;
      width: 100%;
      .image-post {
        display: flex;
        overflow: hidden;
        flex: 0.8;
        max-width: 900px;
        max-height: 750px;
        .img-post-slider {
          object-fit: none;
          display: block;
          flex-shrink: 0;
          flex-grow: 0;
        }
        .img-post-slider-btn {
          margin-right: 10px;
          all: unset;
          width: 16px;
          height: 16px;
          display: block;
          position: absolute;
          background-color: #464646;
          color: white;
          border-radius: 50%;
          padding: 8px;
          cursor: pointer;
          transition: background-color 100ms ease-in-out;
        }
        .img-post-slider-btn:hover {
          background-color: #606060;
          transform: scale(1.05);
        }
      }
      .post-caption {
        flex: 0.2;
        margin-left: 1px;
        border-left: #353535 solid 0.5px;
        max-width: 480px;
        min-width: 380px;
        background-color: #000;
        border-radius: 0px 0px 10px 0px;
        .postInfo-user {
          height: 10%;
          display: flex;
          border-bottom: #353535 solid 0.5px;

          .postInfo-user-avatar {
            display: flex;
            align-items: center;
            padding: 10px 0px 10px 16px;
            position: relative;
            // display: inline-block;
          }
          .postInfo-user-avatar > img {
            border-radius: 50%;
            object-fit: cover;
          }

          .postInfo-user-info {
            padding: 10px 16px 10px 5px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 7px;
            width: 100%;

            .postInfo-username {
              left: 0;
              color: white;
              font-size: 14px;
              font-weight: 550;
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            }
          }
        }
        .post-text {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .post-text > textarea {
          width: 90%;
          background-color: #262626;
          border: none;
          resize: none;
          height: 180px;
          overflow: auto;
          font-size: 16px;
          color: white;
          font-weight: 450;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .post-text > textarea:focus {
          outline: none;
        }
      }
    }
  }
}

.profile__post {
  // display: flex;
  // justify-content: center;
  // max-height: 260px;
  // width: 32.75%;
  // margin: 0.27%;
  flex-basis: 32.75%; /* Chia 2 phần bằng nhau */
  margin: 0.27%; /* Khoảng cách giữa các phần tử */
  position: relative;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
.profile__post::before {
  content: "";
  display: block;
  padding-top: 100%; /* Tạo hình vuông */
}
.profile__post > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-comment {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 65%;
  border-bottom: #353535 solid 0.5px;

  .post-comment-user {
    width: 85%;
    display: flex;
    padding: 8px 24px;

    .post-comment-user-avatar {
      position: relative;
      width: 44px;
      height: 44px;
      display: inline-block;
    }
    .post-comment-user-avatar > img {
      margin-top: 3px;
      border-radius: 50%;
      object-fit: cover;
    }

    .post-comment-user-info {
      width: 100%;
      margin-bottom: 7px;
      display: inline-block;
      word-wrap: break-word;
      .post-comment-content {
        width: 100%;
        max-width: max-content;
        white-space: pre-line;
        word-wrap: break-word;
        color: white;
        font-weight: 400;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
      }

      .post-comment-username {
        margin-right: 5px;
        color: white;
        font-weight: 700;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Helvetica, Arial, sans-serif;
        margin-bottom: 5px;
      }
    }
  }

  .moreCmt{
    display: none;
    cursor: pointer;
  }
  
  .post-comment-user:hover .moreCmt{
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-right: 10px;
  }

}



//more
.more-content {
  border: #353535 solid 0.5px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 400px;
  height: fit-content;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  .more-content-element {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .more-content-element:last-child {
    border-bottom: none;
  }
  
  .more-content-header {
    display:flex;
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    .more-content-title{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 15px;
      margin-right: 27px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    }
  }
  .more-content-report {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .more-content-report:hover{
    background: #434343;
  }
  .more-content-element:hover{
    background: #434343;
  }
  .more-content-element:last-child {
    border-bottom: none;
    border-radius: 0px 0px 10px 10px;
  }
  .more-content-element:first-child {
    border-radius: 10px 10px 0px 0px;
  }
}
