.messages {
    background-color: black;
    padding: 0px 20px 20px 20px;
    overflow: auto;
}

.loading-icon{
    animation: spin 1s infinite linear;
  }
  
@keyframes spin {
from {
    transform: rotate(0deg);
}
to {
    transform: rotate(360deg);
}
}