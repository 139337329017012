.input__message{
    width: 90%;
    background-color: black;
    padding: 0px 10px;
    align-items: center;
    border: #212121 solid 1px;
    border-radius: 30px 30px 30px 30px;
    margin: auto;
    .images{
        padding: 20px 0px 0px 12px;
        .image-wrapper{
            width: 47.5px;
            height: 47px;
            align-items: center;
            justify-content: "center";
            margin-right: 12px;
            margin-bottom: 20px;
            object-fit: contain;
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
        }
    }
}
.input {
    height: 44px;
    width: 100%;
    background-color: black;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    // justify-content: space-between;

    
    input {
        flex: 1;
        border: none;
        outline: none;
        background-color: black;
        color: white;
        padding: 0px 15px;
        font-size: 14px;

        &::placeholder {
            color: #a7b1ae;
        }
    }

    .send {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            height: 24px;
            cursor: pointer;
        }

        button {
            border: none;
            padding: 10px 15px;
            color: white;
            background-color: #8da4f1;
            cursor: pointer;
        }
    }
}