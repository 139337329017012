html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: black;
}
.editProfilePage {
    display: flex;
    flex-direction: row;
    background-color: black;
    height: 100%;


    .editProfile__navWraper {
        position: relative;
        width:240px;
    }

    .editProfile__sideBar {
        z-index: 0;
        position: relative;
        width: 315px; 
    }
    .editProfile__content{
        z-index: 0;
        flex: 1;
        height: 100vh;

        .editProfile__content__main{
            padding: 36px 0px;
            .editProfile__content__title{
                margin: 24px 48px 48px  48px;
                padding: 0px 16px;
                color: white;
                font-size: 25px;
                font-weight: 600;
                font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
            }
            .editProfile__content__info{
                display: flex;
                margin-bottom: 30px;
                .editProfile__content__info__subject{
                    width: 35%;
                    display: flex;
                    justify-content: end;
                    margin-right: 30px;
                }
                .editProfile__content__info__subject > span{
                    display: flex;
                    align-items: center;
                    color: white;
                    font-size: 16px;
                    font-weight: 600;
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
                }
                .editProfile__content__info__textarea {
                    width: 50%;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .editProfile__content__info__textarea > input {
                    padding: 10px;
                    border-radius: 10px;
                    width: 100%;
                    background-color: #262626;
                    border: none;
                    resize: none;
                    
                    overflow: auto;
                    font-size: 16px;
                    color: white;
                    font-weight: 450;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                }
                    .editProfile__content__info__textarea > input:focus {
                    outline: none;
                }
                .editProfile__content__info__button > button{
                    height: 32px;
                    width: 80px;
                    margin-left: 10px;
                    background-color: #0095f6;
                    font-size: 14px;
                    color: white;
                    font-weight: 600;
                    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
                        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
                        sans-serif;
                    border-radius: 10px;
                    border: none;
                    cursor: pointer;
                }
            }
        }
    }
}


@media (max-width: 1064px){
    .editProfilePage {
      .editProfile__navWraper {
        position: relative;
        width: 80px;
      }
    }
  }

