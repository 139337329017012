.chats{
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 1;
    background-color: black;
    height: 100%;
    width: 400px;
    border-right: #212121 solid 1px;
    border-radius: 0px 20px 20px 0px;
    .chats__title{
        padding: 36px 24px 24px;
    }
    .chats__title > span{
        color: white;
        font-size: 20px;
        font-weight: 700;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
    }
    .chats__input {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 24px;
    }
    .chats__input input{
        background-color: rgb(38, 38, 38);
        width: 333px;
        height: 34px;
        padding: 3px 16px 3px 16px;
        border-radius: 10px;
        border: 0;
        color: white;
        font-size: 15px;
    }
    .chats__input input:focus {
        outline: none;
      }

    .chats__content{
        height: 100%;
        overflow: auto;
    }
    
    .chats__messages {
        color: white;
        margin: 0px 24px 10px 24px; 
        font-weight: 600;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
    }
  
}
.chats__content::-webkit-scrollbar-track {
    background-color: #424242; /* Màu nền của thanh cuộn */
}

.chats__content::-webkit-scrollbar-thumb {
    background-color: #686868; /* Màu của thanh cuộn */
}


@media (max-width: 900px){
    .chats {
        width: 120px; 
        .chats__title{
            display: none;
        }
        .chats__input{
            display: none;
        }
        .chats__messages{
            margin-top: 20px;
        }
    }
}