html {
  height: 100%;
  margin: 0;
  padding: 0;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.back-ground {
  background-color: white !important;
  height: 100vh;
  margin-bottom: 44px;
  display: flex;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  justify-content: center;
  background-color: transparent;
  min-width: 0;
  box-sizing: border-box;
  align-items: center;
  flex-direction: row;
  align-self: auto;
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  flex-grow: 1;
}

.logo__container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Đảm bảo logo chiếm toàn bộ chiều cao của màn hình */
}
