.open__user{
    display: flex;
    padding: 8px 24px;
    .open__user_avatar{
      width: 44px;
      height: 44px;
      margin-right: 12px;
    }
    .open__user__info {
      display: flex;
      flex-direction: column;
      margin-left: 7px;
      .open__relation {
        color: #A8A8A8;
        font-weight: 400;
      }
      
      .open__username {
        color: white;
        font-weight: 600;
      }
    }
    .user__info > span {
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
    }
}
.open__user:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.128);
    .sidenav__icon{
        transform: scale(1.05);
    }
}