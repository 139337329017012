html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: black !important;
}
.profile {
  display: flex;
  background-color: black;
  height: 100%;

  .profile__navWraper {
    position: relative;
    width: 240px;
  }
  .profile__right {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile__content {
    height: 774px;
    width: 90%;
    padding: 30px 20px;

    .profile__header {
      display: flex;
      padding-bottom: 50px;
      border-bottom: #202020 solid 1px;

      .profile_avatar {
        margin-right: 30px;
        height: 150px;
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        .avatar {
          width: 50%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .profile__info div {
        color: white;
      }

      .profile__info {
        .profile__user > span {
          font-size: 20px;
          font-weight: 400;
          margin: 5px 20px 0px 0px;
          align-items: center;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .profile__user {
          display: flex;
          margin-bottom: 20px;

          .profile__button {
            width: auto;
            align-items: center;
            color: white;
            padding: 7px 15px 8px 15px;
            border: 0;
            font-size: 14px;
            margin: 5px 0px 5px 10px;
            background-color: #363636;
            border-radius: 7px;
            font-weight: 600;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
          }
          .profile__button:hover {
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0.128);
          }
          .profile__button__blue {
            width: auto;
            align-items: center;
            color: white;
            padding: 7px 15px 8px 15px;
            border: 0;
            font-size: 14px;
            margin: 5px 0px 5px 10px;
            background-color: #0095f6;
            border-radius: 7px;
            font-weight: 600;
            font-family: system-ui, -apple-system, BlinkMacSystemFont,
              "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
              "Helvetica Neue", sans-serif;
          }
          .profile__button__blue:hover {
            cursor: pointer;
            background-color: rgb(24, 119, 242);
          }
        }

        .profile__user__2 {
          margin-bottom: 20px;
        }
        .profile__user__2 > span {
          font-size: 16px;
          font-weight: 400;
          margin: 0px 0px 0px 0px;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .profile__user__2 > a {
          font-size: 16px;
          font-weight: 400;
          margin: 0px 0px 0px 40px;
          align-items: center;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
          &:hover {
            cursor: pointer;
            opacity: 0.8;
          }
        }

        .profile__user__3 > span {
          font-size: 14px;
          font-weight: 600;
          margin: 0px 0px 0px 0px;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }

    .profile__post__tag {
      display: flex;
      justify-content: center;
      align-items: center;
      .choose {
        color: #a8a8a8;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 50px;
        height: 52px;

        cursor: pointer;
        .icon {
          width: 12px;
          margin-right: 7px;
        }
        .span {
          font-size: 12px;
          font-weight: 600;
          margin: 0px 0px 0px 0px;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
      }
    }
    .profile__posts {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; /* Chiều ngang */
    }
  }
}

//Modal
.active-profile-modal {
  overflow-y: hidden;
}
profile-modal .btn-profile-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.profile-modal,
.profile-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.profile-overlay {
  background: rgba(4, 3, 3, 0.8);

  .sidenav__icon {
    transform: scale(1.05);
  }
}

//friend-requests
.profile-modal-content {
  border: #353535 solid 0.5px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 400px;
  height: fit-content;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  .profile-modal-content-header {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .profile-modal-content-users {
    height: 360px;
    overflow: auto;
  }
  .profile-modal-content-no-users {
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profile-modal-content-no-users > span {
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
  }
}

.active-post-modal {
  overflow-y: hidden;
}
.post-modal .btn-post-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.post-modal,
.post-overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

//more
.more-content {
  border: #353535 solid 0.5px;
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 400px;
  height: fit-content;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  .more-content-element {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 500;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .more-content-element:last-child {
    border-bottom: none;
  }

  .more-content-header {
    display: flex;
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    .more-content-title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 15px;
      margin-right: 27px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif;
    }
  }
  .more-content-report {
    text-align: center;
    padding: 14px 10px;
    border-bottom: #4e4d4d solid 1px;
    color: white;
    font-weight: 400;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
    cursor: pointer;
  }
  .more-content-report:hover {
    background: #434343;
  }
  .more-content-element:hover {
    background: #434343;
  }
  .more-content-element:last-child {
    border-bottom: none;
    border-radius: 0px 0px 10px 10px;
  }
  .more-content-element:first-child {
    border-radius: 10px 10px 0px 0px;
  }
}

.post-overlay {
  background: rgba(4, 3, 3, 0.8);

  .sidenav__icon {
    transform: scale(1.05);
  }
}

@media (max-width: 1064px) {
  .profile {
    .profile__navWraper {
      position: relative;
      width: 80px;
    }
  }
}
