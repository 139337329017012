.modal__group{
    display: flex;
    padding: 8px 12px;
  }
  .modal__group_avatar{
    position: relative;
    display: flex;
    align-items: center;
    width: 44px;
    height: 44px;
    margin-right: 12px;
  }
  .modal__group_avatar > img {
    border-radius: 10px;
    object-fit: cover;
  }
  .modal__group_avatar > span {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: green;
    bottom: 5px;
    border: black solid 3px;
    left: 85%;
    transform: translate(-50%, 50%);
  }
  .modal__group__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 7px;
    justify-content: center;
    .modal__group__relation {
        color: #A8A8A8;
        font-weight: 400;
        font-size: 12px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    }
    
    .modal__group__username {
        color: white;
        font-weight: 600;
        font-size: 14px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    }
  }
  .modal__group__button{
      width: auto;
      align-items: center;
      color: white; 
      padding: 7px 15px 8px 15px;
      border: 0;
      font-size: 14px;
      margin: 5px 0px 5px 10px;
      background-color: #363636;
      border-radius: 7px;
      font-weight: 600;
      font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .modal__group__button:hover {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.128);
  }
  .modal__group__button__accept{
      width: auto;
      align-items: center;
      color: white; 
      padding: 7px 15px 8px 15px;
      border: 0;
      font-size: 14px;
      margin: 5px 0px 5px 10px;
      background-color:rgb(2, 103, 171);
      border-radius: 7px;
      font-weight: 600;
      font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  .modal__group__button__accept:hover {
      cursor: pointer;
      background-color:rgb(0, 149, 246);
  }