.post {
    width: 550px;
    margin: 20px 0px 20px 0px;
    padding-bottom: 16px;
    border-bottom: #202020 solid 1px;
}
  
.post__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    .post__more {
    color: white;
    cursor: pointer;
    }
    .post__more:hover {
    color: rgb(110, 110, 110);
    cursor: pointer;
    }
}

.post__headerAuthor {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bolder;
    color: white;
}

.post__headerAuthor > span {
    color: grey;
    font-size: 15px;
    margin-left: 5px;
    font-weight: 500;
}

.post__image {
    width: 100%;
    border-radius: 6px;
    background-color: black;
    .image-post {
    display: flex;
    overflow: hidden;
    flex: 0.8;
    align-items: center;
    max-width: 900px;
    max-height: 750px;
    .img-post-slider {
        object-fit: none;
        display: block;
        max-height: 550px;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .img-post-slider-btn {
        margin-right: 10px;
        all: unset;
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        background-color: #464646;
        color: white;
        border-radius: 50%;
        padding: 8px;
        cursor: pointer;
        transition: background-color 100ms ease-in-out;
    }
    .img-post-slider-btn:hover {
        background-color: #606060;
        transform: scale(1.05);
    }
    }
}

.post__footer {
    margin-top: 20px;
    bottom: 0;
    color: white;
    font-weight: 700;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
    display: flex;
    justify-content: space-between;
    padding: 0 10%;
}

.footer__button{
    width: 40%;
    align-items: center;
    color: white; 
    padding: 7px 15px 8px 10px;
    border: 0;
    font-size: 14px;
    background-color: #363636;
    border-radius: 7px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer__button:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.128);
}

.footer__button__accept{
    width: 40%;
    align-items: center;
    color: white; 
    padding: 7px 15px 8px 10px;
    border: 0;
    font-size: 14px;
    background-color:rgb(2, 103, 171);
    border-radius: 7px;
    font-weight: 600;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.footer__button__accept:hover {
    cursor: pointer;
    background-color:rgb(0, 149, 246);
  }
  
  

  