.open__user{
    display: flex;
    padding: 8px 24px;
    .open__user_avatar{
      margin-right: 12px;
      background: #222222;
      background-size: 100px 20px;
      background-image:			
          linear-gradient(
              90deg, 
              rgba(#000, 0.5), 
              rgba(#000, 0.5),
              rgba(#000, 0.5)
          );
        background-repeat: no-repeat; 
        background-position: left 0px top -40px; 
        animation: shine 1s ease infinite; 
    }
    .open__user__info {
      display: flex;
      flex-direction: column;
      margin-left: 7px;
      .open__relation {
        background-color: #222222;
        font-weight: 400;
        width: 200px;
        height: 15px;
        border-radius: 10px;
        margin: 10px 0px 0px 0px;
        background-size: 400px 10px;
        background-image:			
            linear-gradient(
                90deg, 
                rgba(#000, 0.5), 
                rgba(#000, 0.5),
                rgba(#000, 0.5)
            );
        background-repeat: no-repeat; 
        background-position: left 0px top -40px; 
        animation: shine 1s ease infinite; 
      }
      
      .open__username {
        background: #222222;
        font-weight: 600;
        width: 250px;
        height: 15px;
        border-radius: 10px;
        margin: 2px 0px 0px 0px;
        background-size: 500px 10px;
        background-image:			
            linear-gradient(
                90deg, 
                rgba(#000, 0.5), 
                rgba(#000, 0.5),
                rgba(#000, 0.5)
            );
        background-repeat: no-repeat; 
        background-position: left 0px top -40px; 
        animation: shine 1s ease infinite; 
      }
    }
    .user__info > span {
      font-size: 14px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif
    }
}

@keyframes shine {
  to {
      background-position: left 0 top 40px;
  }
}