.group__info {
    // display: flex;
    // justify-content: center;
    // max-height: 260px;
    // width: 32.75%;
    // margin: 0.27%;
    flex-basis: 30.75%; /* Chia 3 phần bằng nhau */
    margin: 0.27%; /* Khoảng cách giữa các phần tử */
    padding: 1%;
    
    background-color: #242526;
    border-radius: 5px;
  }
.group__inf{
    display:flex;
    .group__avatar::before {
        content: "";
        display: block;
        padding-top: 100%; /* Tạo hình vuông */
    }
    .group__avatar{
        position: relative;
        width: 30%;
    }
    .group__avatar > img {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.group__inf span {
    color: white;
    font-size: 14px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont,
        "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
        "Helvetica Neue", sans-serif;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    overflow: auto;
}
.group__btn{
    margin-top: 10px;
    .join__button {
        width: 100%;
        align-items: center;
        color: white;
        padding: 7px 15px 8px 15px;
        border: 0;
        font-size: 14px;
        background-color: #363636;
        border-radius: 7px;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;
    }
    .join__button:hover {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.128);
    }

    .accept__button {
        width: 47%;
        align-items: center;
        color: white;
        padding: 7px 15px 8px 15px;
        border: 0;
        font-size: 14px;
        background-color: rgb(2, 103, 171);
        border-radius: 7px;
        font-weight: 600;
        font-family: system-ui, -apple-system, BlinkMacSystemFont,
            "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
            "Helvetica Neue", sans-serif;
    }
    .accept__button:hover {
        cursor: pointer;
        background-color: rgb(0, 149, 246);
    }
}