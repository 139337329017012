.sidenav {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: black;
  height: 100%;
  width: 240px;
  border-right: #212121 solid 1px;
  transition: width 0.5s ease; /* Hiệu ứng chuyển đổi mượt mà */
}
.sidenav__title {
  height: 120px;
  display: flex;
  align-items: center;
  .sidenav__title__button {
    display: none;
  }
}

.sidenav__logo {
  width: 120px;
  margin: 25px;
  margin-left: 40px;
}

.sidenav__buttons {
  display: flex;
  flex-direction: column;
}

.sidenav__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  background: 0;
  border: 0;
  margin: 5px 0px 5px 10px;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
  width: 90%;
  position: relative;
  // display: inline-block;
  .unread{
    display: flex;
    justify-content: center; /* Căn giữa ngang */
    align-items: center; /* Căn giữa dọc */
    position: absolute;
    width: 20px;
    height: 19px;
    align-items: center;
    border-radius: 50%;
    background-color: rgb(255, 8, 8);
    font-size: 12px;
    font-weight: 550;
    bottom: 40px;
    left: 10%;
    transform: translate(-50%, 50%);
  }
}

.sidenav__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.128);
  .sidenav__icon {
    transform: scale(1.05);
  }
}

.sidenav__button > span {
  font-size: 15px;
  font-weight: 300;
  margin-left: 20px;
}

.sidenav__button_avatar {
  width: 27px;
  height: 27px;
}

.sidenav__more {
  position: fixed;
  bottom: 15px;
  width: 240px;
  //more
  .sidenav__more-content {
    border: #353535 solid 0.5px;
    position: absolute;
    z-index: 0;
    width: 250px;
    height: fit-content;
    background: #262626;
    border-radius: 10px;
    .sidenav__more-element{
      text-align: center;
      padding: 14px 10px;
      color: white;
      font-weight: 500;
      font-size: 15px;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
      cursor: pointer;
    }
    .sidenav__more-element:hover{
      background: #4e4d4d;
    }
    .sidenav__more-element:first-child{
      border-bottom:none;
      border-radius: 10px 10px 0px 0px;
    }
    .sidenav__more-element:last-child{
      border-bottom:none;
      border-radius: 0px 0px 10px 10px;
    }
  }
}

.logout__button {
  font-size: 15px;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  background-color: rgba(255, 255, 255, 0.211);
  border: 0;
  padding: 10px;
}

.logout__button:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.424);
}

.open {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
  transform: translateX(-200%);
  transition: transform ease 0.5s;
  background-color: black;
  height: 100%;
  width: 400px;
  border-right: #212121 solid 1px;
  border-radius: 0px 20px 20px 0px;
  .open__title {
    padding: 12px 24px 36px 24px;
    margin: 8px 0px 8px 0px;
  }
  .open__title > span {
    color: white;
    font-size: 24px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
  }
  .open__input {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: #212121 solid 1px;
  }
  .open__input input {
    background-color: rgb(38, 38, 38);
    width: 333px;
    height: 34px;
    padding: 3px 16px 3px 16px;
    border-radius: 10px;
    border: 0;
    color: white;
    font-size: 15px;
    font-weight: 400;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
  }
  .open__input input:focus {
    outline: none;
  }

  .open__content {
    height: 100%;
    overflow: auto;
  }

  .open__content__span {
    color: white;
    margin: 10px 24px 8px 24px;
    margin-top: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif;
  }
  .loading-icon{
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
  }
}

//Modal
.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.overlay {
  background: rgba(4, 3, 3, 0.8);
}
.modal-navbar-content {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 80%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #262626;
  border-radius: 10px;
  max-width: 900px;
  min-width: 300px;
  min-height: 400px;
  .modal-header {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: #303030 solid 1px;
    font-size: 16px;
    font-weight: 600;
    padding: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    .header-next {
      font-size: 14px;
      color: #0095f6;
    }
    .header-next:hover {
      color: white;
      cursor: pointer;
    }
  }
  .modal-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93.5%;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    .container {
      width: 100%;
      .image {
        flex: 0.8;
        max-width: 567px;
        max-height: 567px;
        .img-slider {
          object-fit: none;
          display: block;
          flex-shrink: 0;
          flex-grow: 0;
        }
        .img-slider-btn {
          margin-right: 10px;
          all: unset;
          width: 16px;
          height: 16px;
          display: block;
          position: absolute;
          background-color: #464646;
          color: white;
          border-radius: 50%;
          padding: 8px;
          cursor: pointer;
          transition: background-color 100ms ease-in-out;
        }
        .img-slider-btn:hover {
          background-color: #606060;
          transform: scale(1.05);
        }
        .delete_image:hover {
          background-color: #606060 !important;
          transform: scale(1.05);
        }
      }
      .post__caption {
        flex: 0.4;
        // width: 322px;
        background-color: #262626;
        border-radius: 0px 0px 10px 0px;
        .postInfo__user {
          display: flex;
          margin: 10px 16px;

          .postInfo__user_avatar {
            padding: 6px;
            position: relative;
            display: inline-block;
          }
          .postInfo__user_avatar > img {
            border-radius: 50%;
            object-fit: cover;
          }

          .postInfo__user__info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: 7px;

            .postInfo__username {
              left: 0;
              color: white;
              font-size: 14px;
              font-weight: 550;
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
                "Helvetica Neue", sans-serif;
            }
          }
        }
        .post__text {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .post__text > textarea {
          width: 90%;
          background-color: #262626;
          border: none;
          resize: none;
          height: 180px;
          overflow: auto;
          font-size: 16px;
          color: white;
          font-weight: 450;
          font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
            Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
            sans-serif;
        }
        .post__text > textarea:focus {
          outline: none;
        }
      }
    }
  }
  .modal-image {
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-logo {
      width: 100%;
      height: 100px;
      color: white;
    }
  }

  .modal-text {
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 5500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .modal-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .modal-upload {
    background-color: #0095f6;
    color: white;
    padding: 7px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    margin-bottom: 30px;
    cursor: pointer;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

@media (max-width: 1064px) {
  .sidenav {
    width: 80px;
  }
  .sidenav__title {
    height: 120px;
    display: flex;
    align-items: center;
    .sidenav__title__button {
      display: block;
    }
    .sidenav__logo {
      display: none;
    }
  }
  .sidenav__button {
    width: 71% !important;
    margin: 5px 10px 5px 10px;
  }
  .sidenav__button .unread{
    left: 20px;
  }
  .sidenav__button .span {
    display: none;
  }
  .sidenav__more {
    width: 80px;
  }
  .sidenav__buttonText{
    display: none;
  }

}


